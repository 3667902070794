import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0cb4bc96&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=0cb4bc96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb4bc96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainHeader: require('/opt/new.yaravia.ru/src/components/main-header.vue').default,InfoMessage: require('/opt/new.yaravia.ru/src/components/info-message.vue').default,MainFooter: require('/opt/new.yaravia.ru/src/components/main-footer.vue').default})
